@import '~antd/lib/style/themes/default.less';
@import '../../utils/utils.less';

.widgetCardWrapper {
  border-radius: 5px;
  box-shadow: 0 0 10px 0 @overviewBoxShadow;
  border: solid 2px var(--white);
  background-color: @overviewBackgroundColour;
  width: 100%;
  height: 100%;
  padding: 50px 35px;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
}
.widgetWrapper {
  display: flex;
  justify-content: space-between;
}
.widgetWrapperCenter {
  display: flex;
  justify-content: center;
}
.progressCircleWrapper,
.progressCirclePadding {
  display: flex;
  justify-content: center;
  padding: 10px;
}
.progressCirclePadding {
  width: 33%;
}
.widgetCarousel {
  display: none;
}

.warningLine,
.warningLineMobile {
  display: flex;
  align-items: flex-start;
  margin-top: 5px;
  .warningIcon {
    margin: 2px 8px 0 0;
  }
  .warningText {
    line-height: 1.43;
    color: rgba(36, 45, 65, 0.7);
  }
}
.warningLineMobile {
  display: none;
}

@media screen and (max-width: @screen-xl) {
  .widgetWrapper {
    padding: 10px;
    display: block;
  }
}

@media screen and (max-width: @screen-md) {
  .widgetWrapper {
    padding: 10px;
    display: block;
  }
}

@media screen and (max-width: @screen-sm) {
  .widgetWrapper,
  .warningLine {
    display: none;
  }
  .warningLineMobile {
    display: block;
  }
  .widgetCarousel {
    display: inline-flex !important;
    width: 100%;
    :global {
      .ant-carousel {
        width: 100%;
      }
    }
  }
  .widgetCarouselItem {
    display: inline-flex !important;
    flex-wrap: wrap;
    justify-content: center;
    margin-top: 10px;
    padding: 0 5px;
    :global {
      .ant-col {
        margin-bottom: 16px;
      }
    }
  }
  .widgetCardWrapper {
    display: none;
  }
}
