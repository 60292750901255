@import '../../../utils/utils.less';

.signaturePad {
  background-color: @white;
  width: 100%;
  padding: 15px;
  border-radius: 2px;
  border: solid 1px rgba(36, 45, 65, 0.1);
  box-sizing: border-box !important;
}

.signatureHelper {
  width: 100%;
  margin-top: 8px;
  height: 32px;
  color: rgba(36, 45, 65, 0.4);
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.clearButton {
  border: 1px solid #d9d9d9;
  border-radius: 4px;
  padding: 0 15px;
  height: 32px;
}
