.rowWrapper {
  display: flex;
  align-items: flex-start;
  margin-top: 10px;
  margin-bottom: 10px;

  img {
    margin-right: 8px;
    margin-top: 3px;
  }
}
