@import '~antd/lib/style/themes/default.less';
@import '../../utils/utils.less';

.buttonsDiv {
  display: flex;
  justify-content: space-between;
  padding-bottom: 14px;
}

.graphWrapper {
  padding: 24px;
}

.pageTitle {
  margin-bottom: 15px;
  font-size: 16px;
  font-weight: 500;
  color: #242d41;
}

.cardStyling,
.cardStylingSmall {
  :global {
    .ant-card-head-title {
      padding: 28px 0;
    }
    .ant-card-head {
      font-size: 14px;
      font-weight: 500;
      letter-spacing: 0.1px;
      color: #868686;
      padding: 0 28px;
    }
    .ant-card-body {
      min-height: 530px;
      padding: 28px;
    }
  }
}

.cardStylingSmall {
  :global {
    .ant-card-body {
      min-height: 320px;
    }
  }
}

.graphLoadingWrapper {
  height: 400px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.hideDropdown {
  display: none;
}

.dropdown {
  display: inline;
}

@media (max-width: 450px) {
  .pageWrapper {
    padding: 0 4px;
  }
  .pageTitle {
    margin-left: 24px;
  }
}
