@import '~antd/lib/style/themes/default.less';
@import '../../utils/utils.less';

.headerWrapper {
  :global {
    #searchWrapper .ant-layout,
    .ant-layout * {
      -webkit-box-sizing: inherit;
      box-sizing: inherit;
    }
    #searchWrapper {
      -webkit-box-sizing: unset;
      box-sizing: unset;
    }
  }
}

.menuOptions {
  :global {
    .ant-dropdown-menu-item:hover,
    .ant-dropdown-menu-submenu-title:hover {
      background-color: rgba(18, 111, 214, 0.05);
    }
  }
}
