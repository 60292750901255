.dateWrapper {
  :global {
    .ant-calendar-picker:hover .ant-calendar-picker-input:not(.ant-input-disabled) {
      border: solid 1px #126fd6;
    }
    .ant-input:hover {
      border: solid 1px #126fd6;
    }
    .ant-input:focus {
      border-color: #126fd6;
      box-shadow: none;
    }
  }
}

.pdfIcon {
  width: 16px;
  height: 16px;
  margin-right: 4px;
}

:global {
  .ant-calendar-footer-btn {
    a {
      color: #126fd6;
    }
  }
  .ant-calendar-today .ant-calendar-date {
    color: #126fd6;
    border-color: #126fd6;
    background-color: rgba(18, 111, 214, 0.05);
  }
}
