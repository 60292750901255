@import '~antd/lib/style/themes/default.less';
@import '../utils/utils.less';

@nav-header-height: 64px;
@left-sider-height: 862px;

.headerLayout {
  padding: 0;
  width: 100%;
  background: @white;
  border-bottom: 1px solid @brandLightGray;
  line-height: 1rem;
}

.fixedRightMenu {
  min-width: 203px;
  position: fixed;
  top: 0;
  right: 0;
  background: @white;
  border-bottom: 1px solid @brandLightGray;
  height: 64px;
  @media only screen and (max-width: @screen-md) {
    :global {
      .antd-pro-components-global-header-index-name {
        display: none;
      }
    }
  }
}

.topNavWrapper {
  img {
    padding-top: 0;
    vertical-align: baseline;
  }
}

.planeContext {
  display: inline-block;
  padding-left: 20px;
  padding-right: 5px;
  top: @nav-header-height + 1;
}

.topMenu {
  position: relative;
  width: ~'calc(100vw - 140px - 200px - 80px - 142px - 55px)';
  transition: width 0.2s;
  line-height: 64px;
  display: inline-block;
  @media (max-width: @screen-md) {
    width: ~'calc(100vw - 140px - 200px - 142px)';
  }
}

.topMenuWhenSideCollapsed {
  width: ~'calc(100vw - 140px - 200px - 200px - 142px - 55px)';
  @media (max-width: @screen-md) {
    width: ~'calc(100vw - 140px - 200px - 142px)';
  }
}

.wrapperSider {
  height: 100vh;
  box-shadow: 2px;
  position: relative;
  z-index: 10;
  :global(.ant-menu) {
    background-color: transparent;
  }
  :global(.ant-layout-slider) {
    background-color: transparent;
  }
}

.aircraftList {
  height: ~'calc(100vh - 64px)';
  overflow-y: auto;
}

.aircraftList::-webkit-scrollbar {
  display: none !important;
}

.mobileAircraftList {
  overflow-y: scroll;
  @media (max-height: 1024px) {
    height: ~'calc(100vh - 192px)';
  }
  @media (max-width: @screen-sm) {
    height: ~'calc(100vh - 192px)';
  }
}

.scrollableSider {
  background-color: transparent;
  &.fixSiderbar {
    position: fixed;
    top: 0;
    left: 0;
    :global(.ant-menu-root) {
      overflow-y: auto;
      height: ~'calc((100vh - @{nav-header-height}) + 4px)';
    }
  }
  &.dark {
    box-shadow: 0 0 8px 0 @grayExtraLight;
    background-color: @offWhite;
    :global(.ant-menu-dark) {
      border-right-color: transparent;
    }
  }
}

.filterBox {
  width: 128px;
  display: inline-block;
  height: @nav-header-height;
  :global(.ant-input) {
    border-radius: 0;
    background-color: @brandBlue;
    border: 1px solid @brandBlue !important;
    border-top: 3px solid @brandBlue !important;
    color: @innerMenuWhite;
    padding-bottom: 8px;
    &:hover {
      border-color: @brandBlue;
    }

    &:focus {
      border-color: @brandBlue;
      box-shadow: @transparent;
    }
  }
  .searchIcon {
    color: @innerMenuWhite;
    pointer-events: none;
    :hover {
      border-color: @brandBlue !important;
    }
  }

  ::placeholder {
    color: @brandLightGray;
  }

  .mobielFilterBox {
    border-top: 1px solid @brandBlue;
  }
}

.contextMenuItem {
  padding-right: 0;
  padding-left: 19px;
}

.innerContent {
  padding-left: 24px;
  padding-right: 24px;
  padding-top: 24px;

  @media (max-width: @screen-md) {
    margin-top: 0;
    padding-left: 0;
    padding-right: 0;
    -webkit-transition: padding-right 0.3s ease, padding-left 0.3s ease;
    transition: padding-right 0.3s ease, padding-left 0.3s ease;
  }
}

.contentWrapper {
  width: 100%;
}

.searchMenuWrapper {
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  background: red;
}

:global {
  .antd-pro-layouts-inner-menu-layout-searchMenuWrapper {
    & + .ant-layout {
      overflow: hidden;
    }
  }
}

.mobileSearchMenuWrapper {
  position: fixed;
  top: 129px;
  left: 0;
  z-index: 999;
  @media (max-width: @screen-sm) {
    left: -128px;
    -webkit-transition: left 0.3s ease;
    transition: left 0.3s ease;
    &.searchFocussed {
      left: 0;
      -webkit-transition: left 0.3s ease;
      transition: left 0.3s ease;
    }
  }
}

.planeRegistration {
  display: inline-block;
  width: 80px;
  font-size: 14px;
  margin-left: 5px;
  font-weight: 700;
}

.topNavBar {
  margin-left: 0;
}

.mobileLogoSection {
  display: inline-block;
  width: 128px;
}

.mobileContextNavBar {
  margin-top: 2px;
  display: inline-block;
  width: 100vw;
  line-height: 64px;
  background: @brandBlue;
}

i.trigger {
  float: right;
  font-size: 20px;
  height: 64px;
  cursor: pointer;
  transition: all 0.3s, padding 0s;
  padding: 22px 24px;
  &:hover {
    background: @blackExtraLight;
  }
}

.logo {
  padding: 0 0 0 24px;
}

.innerMenuLayout {
  position: relative;
}

.innerMenuLayoutNoBanner {
  position: relative;
  :global {
    .sticky-inner-wrapper {
      transform: translate3d(0, 0, 0) !important;
    }
  }
}

.affixSpacer {
  min-width: 200px;
  max-width: 200px;
  width: 200px;
}
.contextAffix {
  max-height: 100%;
  min-height: 100vh;
  overflow: auto;
  background: @contextMenuBlue;
  width: 200px;
  min-width: 200px;
  position: fixed;
  top: 0;
  :global {
    .ant-affix {
      background: @contextMenuBlue;
      max-height: 100%;
      min-height: 100vh;
      overflow: auto;
      padding-bottom: 30px;
    }
  }

  color: @innerMenuWhite;
}

.secondaryLayout {
  background: @contextMenuBlue;
  display: flex;
  justify-content: space-between;
}

@media screen and (max-width: @screen-lg) {
  .mobileSearchMenuWrapper {
    top: 65px;
    bottom: 0;
    .scrollableSider {
      height: 100%;
    }
  }
}

@media screen and (max-width: @screen-md) {
  .contentWrapper {
    text-align: left;
  }
}
