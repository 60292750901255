@import '~antd/lib/style/themes/default.less';
@import '../../utils/utils.less';

.syncButtonWrapper {
  width: 210px;
  display: flex;
  :global {
    button,
    .syncButton,
    .syncButtonSyncing {
      -webkit-box-sizing: unset;
      box-sizing: unset;
    }
  }
  button,
  .syncButton,
  .syncButtonSyncing {
    cursor: pointer;
    margin-right: 5px;
    border-radius: 50px;
    width: 24px;
    height: 24px;
    background-color: @brandAccentBlue;
    padding: 0;
    border: none;
    img {
      width: 16px;
      height: 16px;
      margin: 0;
    }
  }
  .syncButton,
  .syncButtonSyncing {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .timeUnit {
    font-weight: 500;
  }
  .syncButtonSyncing {
    animation: spin 1s linear infinite;
  }
  @keyframes spin {
    100% {
      transform: rotate(360deg);
    }
  }
}

@media screen and (max-width: @screen-sm) {
  .syncButtonWrapper {
    width: auto;
  }
}
