@import '~antd/lib/style/themes/default.less';
@import '../../utils/utils.less';

.maxFuelSpan {
  margin-left: 4px;
}

.headerWrapper {
  padding-bottom: 20px;
  .title {
    color: @titleBlack;
    font-weight: 500;
    font-size: 18px;
    margin-bottom: 0;
  }
  .subTitle {
    font-size: 14px;
    color: @blackMedium60;
    font-weight: 400;
  }
}

.unitWrapper {
  display: flex;
  span {
    margin-left: 4px;
  }
}

.footerWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 20px;
  .modalFooterCancelButton {
    border-radius: 15px;
    height: 30px;
    margin-left: 16px;
    font-weight: 500;
    color: @brandAccentBlue;
  }
}

.tableListForm {
  :global {
    .ant-form-item {
      margin-bottom: 24px;
      margin-right: 0;
      display: flex;
      > .ant-form-item-label {
        width: auto;
        line-height: 32px;
        padding-right: 8px;
      }
      .ant-form-item-control {
        line-height: 32px;
      }
    }
    .ant-form-item-control-wrapper {
      flex: 1;
    }
  }
  .submitButtons {
    white-space: nowrap;
    margin-bottom: 24px;
  }
}

@media screen and (max-width: @screen-lg) {
  .tableListForm :global(.ant-form-item) {
    margin-right: 24px;
  }
}

@media screen and (max-width: @screen-md) {
  .tableListForm :global(.ant-form-item) {
    margin-right: 8px;
  }
}
