@import '~antd/lib/style/themes/default.less';
@import '../../../utils/utils.less';

.modal {
  :global {
    .ant-modal-body {
      padding: 40px 60px;
      .gradient(linear; @whiteLight, @whiteMedium55Solid; 90deg);
    }
    .ant-modal-close-x {
      img {
        transition: all 0.4s;
      }
      &:hover {
        img {
          transform: translateY(-2px);
        }
      }
    }
  }
}

.dateLabel {
  color: rgba(0, 0, 0, 0.85);
  font-weight: 500;
  margin-right: 20px;
}

.firstLabel {
  margin-right: 16px;
}

.radioInput {
  margin-right: 8px;
}

.toleranceInput {
  margin-right: 4px;
  border-radius: 12px;
  width: 43px;
  :global {
    .ant-input-number-handler-wrap {
      display: none;
    }
    input {
      text-align: center;
    }
  }
}

.modalContent {
  margin-top: 16px;
  padding-bottom: 16px;
}

.iconWrapper {
  text-align: center;
}

.bigIcon {
  max-height: 150px;
  margin-left: -20px;
}

.modalTitle {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  font-size: 18px;
  font-weight: 600;
  color: @titleDarkBlack;
  margin-bottom: 15px;
  span {
    text-transform: capitalize;
    display: inline-block;
    margin: 0 4px;
  }
}

.smallIcon {
  height: 16px;
  margin-right: 6px;
}

.modalDescription {
  color: @titleDarkBlack;
  opacity: 0.7;
  margin-bottom: 20px;
}

.modalInput {
  margin-bottom: 10px;
  border-radius: 2px 0 0 2px;
}

.copyButton {
  padding: 9px 10px;
  border-radius: 0 2px 2px 0;
  height: 40.5px;
}

.modalParentRow {
  display: flex;
  align-items: flex-start;
  padding: 40px 0;
}

.inputWithButton {
  display: flex;
}

.selectFull {
  width: 100%;
}

.timesTitle {
  color: @blackMedium85;
  font-weight: 600;
  letter-spacing: 0.06px;
  width: 100%;
  position: relative;
  margin-bottom: 15px;
  display: flex;
  span {
    background: rgb(250, 251, 253);
    display: inline-block;
    padding-right: 10px;
    position: relative;
    z-index: 1;
  }
  &::after {
    content: '';
    display: block;
    width: 100%;
    height: 1px;
    background: @greyLine;
    position: absolute;
    top: 12px;
  }
}

.footerWrapper {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  .cancelWrapper {
    margin-left: 12px;
  }
}

.centerCol {
  width: 100%;
  display: flex;
  .cancelWrapper {
    margin-left: 20px;
  }
}

.rowSpaceTop {
  margin-top: 20px;
}

.rowSpaceTopBig {
  margin-top: 40px;
}

.areaOption {
  text-transform: capitalize;
}

.modalRow {
  margin: 20px 0;
}

.counterWrapper {
  width: 100%;
  display: flex;
  align-items: center;
  margin-bottom: 14px;
}

.counterButton {
  width: 25px;
  height: 25px;
  padding: 0;
  background: transparent;
  color: @brandAccentBlue;
  border-color: @brandAccentBlue;
  border-radius: 50%;
  font-size: 18px;
  line-height: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 1px;
  &:last-child {
    margin-left: 3px;
  }
}

.toleranceValue {
  margin-right: 8px;
  line-height: 1;
  color: @titleDarkBlack;
  span {
    font-weight: 500;
    display: inline-block;
    margin-right: 4px;
  }
}

.toleranceDescription {
  color: @titleDarkBlack;
  opacity: 0.7;
  span {
    font-weight: 500;
    margin-right: 4px;
  }
}

@media screen and (max-width: @screen-lg) {
  .mxModalWrapper {
    :global {
      .ant-modal {
        width: 768px !important; // need to override inline-styles
      }
    }
  }
}
@media screen and (max-width: @screen-md) {
  .bigIcon {
    max-height: 120px;
    margin-left: -20px;
  }
}

@media screen and (max-width: @screen-sm) {
  .buttonRadioFull {
    width: 100%;
  }
  .bigIcon {
    display: none;
  }
  .iconWrapper {
    display: none;
  }
  .modal {
    :global {
      .ant-modal-body {
        padding: 30px 30px;
      }
    }
  }
}
