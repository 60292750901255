@import 'utils.less';
@import '~antd/lib/style/themes/default.less';

/* FORM */
.buttonRadioGroup {
  border-radius: 2px;
  height: 34px;
  border: solid 1px @mediumLightGray;
  background-color: #fcfcfc;
  color: @gray;
  margin-right: 5px;
  &.buttonRadioGroupActive {
    border: solid 1px @mediumLightBlue;
    background-color: @blueExtraLight;
    color: @blue;
  }
}

.standardButton {
  border-radius: 2px;
  padding-left: 20px;
  padding-right: 20px;
  height: 40px;
  font-weight: 600;
  &.standardButtonWide {
    padding-left: 40px;
    padding-right: 40px;
  }
  &.standardButtonRounded {
    border-radius: 20px;
  }
}

:global {
  .ant-btn-primary {
    &[disabled],
    &[disabled]:hover,
    &[disabled]:focus,
    &[disabled]:active {
      background-color: @brandAccentBlue;
      opacity: 0.3;
      color: @white;
    }
  }
  .ant-tabs.ant-tabs-card .ant-tabs-card-bar .ant-tabs-tab-active {
    color: #126fd6;
  }
  .ant-tabs.ant-tabs-card {
    color: #126fd6;
  }
  .ant-pagination-item-active:focus a,
  .ant-pagination-item-active:hover a {
    color: #1e5bb5;
  }
  .ant-pagination-item-active a {
    color: #126fd6;
  }
  .ant-pagination-item a {
    color: #126fd6;
  }
  .ant-tabs {
    color: #126fd6;
  }
  .ant-tabs .ant-tabs-tab:hover {
    color: #1e5bb5;
  }
  .ant-tabs-nav .ant-tabs-tab-active {
    color: #126fd6;
  }
  .ant-tabs-nav .ant-tabs-tab-active:hover {
    color: #1e5bb5;
  }
  .ant-tabs-nav:hover,
  .ant-tabs-tab-active:hover {
    color: #1e5bb5;
  }
  .ant-btn-link {
    color: #126fd6;
  }
  .ant-btn-link:hover,
  .ant-btn-link:focus {
    color: #1e5bb5;
  }
  .ant-btn-link&:hover {
    color: #1e5bb5;
  }
}

.formItem {
  margin-bottom: 16px;
  :global {
    .ant-form-item-label {
      line-height: 1.25;
      margin-bottom: 8px;
      color: @titleDarkBlack;
      font-weight: 500;
      label {
        &::after {
          display: none;
        }
      }
      span {
        font-weight: 400;
      }
    }
  }
  .formInputNumber {
    border-radius: 2px;
    padding: 0;
    height: auto;
    width: 100%;
    :global {
      .ant-input-number-input {
        padding: 9px 10px;
        height: auto;
      }
    }
  }
}

.formInput {
  border-radius: 2px;
  padding: 9px 10px;
  height: auto;
  &.formInputShortString {
    width: 155px;
  }
}

.formInputNumber {
  border-radius: 2px;
  padding: 0;
  height: auto;
  width: 100%;
  :global {
    .ant-input-number-input {
      padding: 9px 10px;
      height: auto;
    }
  }
}

.formTextArea {
  padding: 9px 10px;
  height: auto;
  border-radius: 2px;
  border: solid 1px rgba(36, 45, 65, 0.1);
  background-color: #f3f7fc;
  font-size: 14px;
  line-height: 1.14;
  letter-spacing: -0.4px;
  color: rgba(36, 45, 65, 0.9);
  outline: none;
}

.formSelect {
  border-radius: 2px;
  padding: 0;
  height: auto;
  :global {
    .ant-select-selection {
      height: auto;
      padding: 4px 10px;
      padding-bottom: 5px;
      padding-left: 0;
      border-radius: 2px;
    }
  }
}

.formDatePicker {
  border-radius: 2px;
  padding: 0;
  height: auto;
  width: 100%;
  :global {
    .ant-input {
      height: auto;
      padding: 9px 10px;
      border-radius: 2px;
    }
  }
}

.formHelperText {
  color: @titleDarkBlack;
  margin-bottom: 8px;
  line-height: 1.3;
  &.noWrap {
    white-space: nowrap;
  }
}
@media screen and (max-width: @screen-md) {
  .buttonRadioGroup {
    height: 44px;
  }
  .formInput,
  .formTextArea {
    padding: 15px 10px;
  }
  .formInputNumber {
    :global {
      .ant-input-number-input {
        padding: 15px 10px;
      }
    }
  }
  .formSelect {
    :global {
      .ant-select-selection {
        padding: 10px 4px;
      }
    }
  }
  .formDatePicker {
    :global {
      .ant-input {
        padding: 15px 10px;
      }
    }
  }
  .standardButton {
    height: 50px;
    &.standardButtonRounded {
      border-radius: 25px;
    }
  }
  .formSingleRowButton {
    width: 100%;
  }
  .formHelperText {
    &.noWrap {
      white-space: normal;
    }
  }
}

@media screen and (max-width: @screen-sm) {
  .formItem {
    :global {
      .ant-form-item-label {
        margin-bottom: 0;
      }
    }
    .buttonRadioGroup {
      margin-bottom: 8px;
    }
  }
}

/* CARD + DESCRIPTION */

.itemCard {
  border-radius: 4px;
  margin-bottom: 24px;
  :global {
    .ant-card-body {
      padding: 20px;
    }
    .ant-descriptions-item {
      padding-bottom: 0;
      vertical-align: top;
    }
    .ant-descriptions-item-label {
      font-size: 12px;
      color: @gray;
      text-transform: uppercase;
    }
    .ant-descriptions-item-content {
      font-weight: 500;
      color: @darkGrey;
      word-break: break-all;
      width: 100%;
      padding-right: 5px;
      .ant-tag {
        white-space: normal;
      }
    }
  }
}

.cardTitle {
  font-weight: 500;
  color: @titleDarkBlack;
  margin-bottom: 10px;
}

.authWrappedItem {
  padding: 5px 12px;
}

@media screen and (max-width: @screen-lg) {
  .itemCard {
    :global {
      .ant-descriptions-view {
        tbody {
          .ant-descriptions-row {
            &:nth-child(2n) {
              .ant-descriptions-item-content {
                margin-bottom: 15px;
              }
            }
            &:last-child {
              .ant-descriptions-item-content {
                margin-bottom: 0;
              }
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: @screen-sm) {
  .itemCard {
    margin-bottom: 16px;
    :global {
      .ant-card-body {
        padding: 14px;
      }
    }
  }
}

@media screen and (max-width: @screen-xs) {
  .itemCard {
    :global {
      tbody {
        display: block;
      }
      .ant-descriptions-row {
        width: 100%;
        display: block;
      }
      .ant-descriptions-item {
        width: 50%;
        display: inline-block;
        &[colspan='2'],
        &[colspan='8'] {
          width: 100%;
        }
      }
    }
  }
}

/* PAGE HEADER */
.addNewButton {
  width: auto;
  border-radius: 20px;
}

@media screen and (max-width: @screen-md) {
  .addNewButton {
    width: auto;
    z-index: 5;
    border-radius: 50%;
    padding: 0 8px;
    .buttonText {
      display: none;
    }
    &.buttonPreserveText {
      border-radius: 20px;
      padding: 0 15px;
      .buttonText {
        display: inline;
      }
    }
  }
}

/* PAGE CARD */
.overCardTitle {
  font-size: 16px;
  color: @titleDarkBlack;
  font-weight: 500;
  letter-spacing: 0.06px;
  margin-bottom: 15px;
}

.pageCard {
  background: @offWhite;
  border: 2px @white solid;
  border-radius: 2px;
  box-shadow: 0 0 10px 0 @whiteShadow;
  :global {
    .ant-card-body {
      padding: 28px;
    }
  }
}

@media screen and (max-width: @screen-sm) {
  .pageCard {
    :global {
      .ant-card-body {
        padding: 24px;
      }
    }
  }
}
