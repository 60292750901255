@import '~antd/lib/style/themes/default.less';
@import '../../utils/utils.less';

.formButton {
  min-width: 80px;
  width: 80px;
  margin-right: 8px;
  margin-bottom: 2px;
}

.tableWrapper {
  max-height: calc(100vh - 290px);
  min-height: 100px;
  overflow-y: scroll;
}

.cardStyling {
  :global {
    .ant-card-body {
      padding: 0;
      background-color: #f6f8fb;
    }
    .ant-table-thead > tr > th {
      background-color: #fff;
    }
  }
}

.pageTitle {
  font-size: 16px;
  color: #181818;
  font-weight: 500;
  letter-spacing: 0.06px;
  margin-bottom: 15px;
}

.buttonText {
  display: flex;
  align-items: center;
  img {
    width: 12px;
    margin-right: 8px;
  }
}

.headerWrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  button {
    margin-bottom: 12px;
    display: flex;
    align-items: center;
    margin-right: 12px;
    img {
      margin-right: 4px;
    }
  }
}

.selectDropdown {
  margin-bottom: 10px;
  min-width: 200px;
}

.rangePicker {
  margin-bottom: 10px;
}

.datePicker {
  width: 100%;
  margin-top: 10px;
  margin-bottom: 10px;
}

.draggerBox {
  margin-bottom: 10px;
}

.spin :global(.ant-spin-container) {
  overflow: visible;
}

.icon {
  font-size: 20px;
}

.emptyText {
  display: flex;
  flex-direction: column;
  padding: 30px 0;
  img {
    height: 115px;
  }
  span {
    color: rgba(36, 45, 65, 0.5);
    font-size: 13px;
    padding-top: 8px;
    letter-spacing: 0.42px;
  }
}

.highlightedRow {
  background: @aquaExtraLight;
}

@media screen and (min-width: @screen-xl) {
  .listImageContainer {
    width: 210px;
    height: 210px;
    .listImage {
      width: 210px;
      height: 170px;
      img {
        max-width: 65%;
      }
    }
    .listContent {
      width: 210px;
      height: 40px;
    }
  }
  .addNewButton {
    float: right;
  }
}

@media screen and (min-width: @screen-lg) and (max-width: @screen-xl) {
  .listImageContainer {
    width: 210px;
    height: 210px;
    .listImage {
      width: 210px;
      height: 170px;
      img {
        max-width: 65%;
      }
    }
    .listContent {
      width: 210px;
      height: 40px;
    }
  }
  .addNewButton {
    float: right;
  }
}

@media screen and (min-width: @screen-md) and (max-width: @screen-lg) {
  .listImageContainer {
    width: 200px;
    height: 200px;
    .listImage {
      width: 200px;
      height: 160px;
      img {
        max-width: 65%;
      }
    }
    .listContent {
      width: 200px;
      height: 40px;
    }
  }
  .addNewButton {
    float: right;
  }
}

@media screen and (min-width: @screen-sm) and (max-width: @screen-md) {
  .listImageContainer {
    width: 220px;
    height: 220px;
    .listImage {
      width: 220px;
      height: 180px;
      img {
        max-width: 65%;
      }
    }
    .listContent {
      width: 220px;
      height: 40px;
    }
  }
  .addNewButton {
    float: right;
    border-radius: 50%;
    padding: 0 8px;
    .buttonText {
      display: none;
    }
  }
}

@media screen and (min-width: @screen-xs) and (max-width: @screen-sm) {
  .listImageContainer {
    width: 240px;
    height: 240px;
    .listImage {
      width: 240px;
      height: 200px;
      img {
        max-width: 65%;
      }
    }
    .listContent {
      width: 240px;
      height: 40px;
    }
  }
  .addNewButton {
    float: right;
    border-radius: 50%;
    padding: 0 8px;
    .buttonText {
      display: none;
    }
  }
}

@media screen and (max-width: @screen-xs) {
  .listImageContainer {
    width: 100%;
    height: 240px;
    .listImage {
      width: 100%;
      height: 200px;
      img {
        max-width: 90%;
        padding-left: 10px;
        padding-right: 10px;
      }
    }
    .listContent {
      width: 100%;
      height: 40px;
    }
  }
  .addNewButton {
    float: right;
    border-radius: 50%;
    padding: 0 8px;
    .buttonText {
      display: none;
    }
  }
}

.thumbnailImage {
  min-width: 60px;
  width: 50%;
  height: auto;
  box-shadow: 0 -1px 1px 0 @grayExtraLight, 0 1px 7px 0 @grayExtraLight;
  transition: all 0.3s ease;
  cursor: pointer;
  img:hover {
    box-shadow: 0 -1px 10px 0 @grayExtraLight, 0 1px 15px 0 @grayExtraLight;
  }
}

@keyframes moveContent {
  0% {
    transform: translateX(150vw);
  }
  100% {
    transform: translateX(0);
  }
}

.animatedTable {
  animation-name: moveContent;
  animation-duration: 0.75s;
  transition-timing-function: ease-in;
  tbody {
    tr {
      height: 120px;
      max-height: 120px;
    }
  }
}

.defaultTable {
  colgroup {
    col:first-child {
      width: 50px;
      min-width: 50px;
    }
  }
  tbody {
    tr {
      height: 120px;
      max-height: 120px;
    }
    td {
      word-break: break-all;
    }
  }
}

.defaultList {
  padding-top: 20px;
}

.animatedList {
  padding-top: 40px;
  animation-name: moveContent;
  animation-duration: 0.75s;
  transition-timing-function: ease-in;
}

.tableButtonInactive {
  color: @lightGray;
}

.gridButtonInactive {
  color: @lightGray;
}

.previewModalHeader {
  color: #313745;
  font-weight: 500;
  font-size: 18px;
  margin-bottom: 20px;
}

.modalContentWrapper {
  height: auto;
  .modalTitle {
    font-weight: 500;
    color: #242d41;
    padding-bottom: 12px;
  }
  .modalMessage {
    line-height: 1.29;
    color: rgba(36, 45, 65, 0.7);
  }
  .modalButtonWrapper {
    display: flex;
    justify-content: flex-start;
    margin-top: 28px;
    .submitButton {
      margin-right: 12px;
    }
    .cancelButton {
      border-radius: 16px;
    }
  }
}

.buttonWrapper {
  display: flex;
  margin-top: 20px;
}

:global {
  .ant-popover-buttons button {
    border-radius: 16px;
    height: 30px;
    padding: 0 16px;
  }
  .ant-popover-buttons {
    .ant-btn-primary {
      background-color: #126fd6;
    }
  }
}

.tableButtonActive {
  color: @blue;
}

.gridButtonActive {
  color: @blue;
}

.footerButton {
  margin-left: 12px;
}

.viewerInputFirst {
  margin-bottom: 20px;
  width: 100%;
}

.viewerInput {
  margin-bottom: 20px;
  width: 100%;
}

.headerFix {
  font-size: 1em !important;
}

.updateModalButton {
  width: 100%;
}

.thickerText {
  font-weight: 700;
}

.fixedModal {
  width: 70% !important;
}

.modalFlexContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  width: inherit;
  height: inherit;
  .modalFlexPreviewContainer {
    width: 100%;
    padding-right: 20px;
    flex-grow: 1;
    embed {
      position: relative;
    }
    img {
      width: inherit;
      height: auto;
    }
  }
  .modalFlexControlContainer {
    flex: 0 0 240px;
  }
}

.modalFlexPreviewContainer {
  position: relative;
  min-height: 100px;
  :global {
    .ant-spin {
      position: absolute;
      z-index: 0;
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}

.modalPreviewContainer {
  padding-bottom: 20px;
  min-height: 100px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  :global {
    .ant-spin {
      position: absolute;
      z-index: 0;
    }
    embed {
      position: relative;
      z-index: 1;
      max-height: 600px;
      object-fit: contain;
    }
  }
}

@media screen and (min-width: @screen-xl) {
  .fixedModal {
    width: 60% !important;
  }
}

@media screen and (min-width: @screen-lg) and (max-width: @screen-xl) {
  .modalFlexContainer {
    flex-direction: row;
    .modalFlexPreviewContainer {
      padding-right: 20px;
    }
    .fixedModal {
      width: 70% !important;
    }
  }
}

@media screen and (min-width: @screen-md) and (max-width: @screen-lg) {
  .modalFlexContainer {
    flex-direction: column;
    .modalFlexPreviewContainer {
      .modalSpinner {
        position: relative;
        left: 50%;
        top: 50%;
        z-index: 0;
      }

      padding-right: 0;
    }
  }
  .fixedModal {
    width: 70% !important;
  }
}

@media screen and (min-width: @screen-md) and (max-width: @screen-lg) {
  .modalFlexContainer {
    flex-direction: column;
    .modalFlexPreviewContainer {
      padding-right: 0;
    }
  }
  .fixedModal {
    width: 80% !important;
  }
}

@media screen and (min-width: @screen-sm) and (max-width: @screen-md) {
  .modalFlexContainer {
    flex-direction: column;
    .modalFlexPreviewContainer {
      padding-right: 0;
    }
  }
  .fixedModal {
    width: 80% !important;
  }
}

@media screen and (min-width: @screen-xs) and (max-width: @screen-sm) {
  .modalFlexContainer {
    flex-direction: column;
    .modalFlexPreviewContainer {
      padding-right: 0;
    }
  }
  .fixedModal {
    width: 100% !important;
  }
}

@media screen and (max-width: @screen-xs) {
  .modalFlexContainer {
    flex-direction: column;
    .modalFlexPreviewContainer {
      padding-right: 0;
    }
  }
  .fixedModal {
    width: 100% !important;
  }
}

@media screen and (max-width: @screen-sm) {
  :global {
    .ant-pagination {
      text-align: right;
    }
    .ant-pagination-options {
      display: block;
      margin-left: 0;
      margin-top: 16px;
      margin-right: -8px;
    }
  }

  .pageTitle {
    padding-left: 24px;
  }

  .cardStyling {
    :global {
      .ant-card-body {
        padding: 0 4px;
      }
    }
  }
}

.singleListItem {
  height: auto;
  display: flex;
  justify-content: center;
  align-items: center;
}

.gridListWrapper {
  padding-top: 32px;
  background-color: #fff;
  :global {
    .ant-pagination {
      padding: 16px 8px 12px 0;
      background-color: #f6f8fb;
    }
  }
}

.highlightWrappedImageContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  flex-flow: column;
  height: auto;
  width: 100%;
  padding: 0 15px;
  .imageContainer {
    width: 100%;
    height: auto;
    box-shadow: 0 -1px 1px 0 rgba(0, 0, 0, 0.01), 0 1px 7px 0 rgba(0, 0, 0, 0.15);
    cursor: pointer;
    background: #ebfff4;
    position: relative;
    &::before {
      padding-top: 100%;
      display: block;
      content: '';
    }
    .imageItself {
      max-width: 90%;
      max-height: 90%;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      border: 1px solid #f5f5f5;
      transition: all 0.3s ease;
      cursor: pointer;
    }
  }

  .imageContainer:hover {
    box-shadow: 0 -1px 10px 0 rgba(0, 0, 0, 0.01), 0 1px 15px 0 rgba(0, 0, 0, 0.2);
    cursor: pointer;
  }

  .textUnderImage {
    margin-top: 8px;
    max-width: 90%;
    height: 22px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    cursor: pointer;
  }
}

.highlightImageContainer:hover {
  box-shadow: 0 -1px 10px 0 rgba(0, 0, 0, 0.01), 0 1px 15px 0 rgba(0, 0, 0, 0.2);
}

.itemWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  flex-flow: column;
  height: auto;
  width: 100%;
  padding: 0 15px;
  .imageContainer {
    width: 100%;
    height: auto;
    box-shadow: 0 -1px 1px 0 rgba(0, 0, 0, 0.01), 0 1px 7px 0 rgba(0, 0, 0, 0.15);
    cursor: pointer;
    position: relative;
    .imageItself {
      width: auto;
      height: auto;
      max-width: 150px;
      max-height: 150px;
      border: 1px solid #f5f5f5;
      cursor: pointer;
    }
  }
}

.imageContainer:hover {
  box-shadow: 0 -1px 10px 0 rgba(0, 0, 0, 0.01), 0 1px 15px 0 rgba(0, 0, 0, 0.2);
  cursor: pointer;
}

.textUnderImage {
  margin-top: 8px;
  max-width: 90%;
  height: 22px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  cursor: pointer;
}
