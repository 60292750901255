@import '../../utils/utils.less';
@import '~antd/lib/style/themes/default.less';

.hoverEnlarge {
  will-change: transform;
  transition: transform 0.35s cubic-bezier(0.215, 0.61, 0.355, 1);
  box-shadow: 2px 2px 12px -2px @lightGray;
  &:hover {
    transform: scale(1.03);
  }
}

.mapDisplaying {
  :global {
    .ant-card-body {
      padding: 24px;
    }
  }
}

.loadRegWrapper {
  width: 100%;
  height: 100%;
  position: relative;
  .loadingWrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
  }
  .preAircraftReg {
    position: absolute;
    top: -8px;
    left: 0;
    color: #000;
    font-weight: 600;
  }
}

.xxsColParent {
  margin-top: -9px;
  .remainingTitle {
    font-weight: 300;
    font-size: 12px;
    color: #ccc;
    text-transform: uppercase;
  }
}

.marginRight {
  margin-right: 4px;
  color: rgb(123, 200, 141);
}

.defectCount {
  color: #f5222d;
  margin-right: 4px;
}

.limitationsCount {
  color: #fa8c16;
  margin-right: 4px;
}

.clickable {
  cursor: pointer;
  &:hover {
    box-shadow: 0 -1px 10px 0 rgba(0, 0, 0, 0.05), 0 1px 20px 0 rgba(0, 0, 0, 0.2);
  }
}

.aircraftCard {
  border: 2px solid @white;
  border-radius: 4px;
  box-shadow: 0 0 15px 5px rgba(219, 227, 237, 0.71);
  min-height: 230px;
  height: 230px;
  position: relative;
  :global {
    .ant-row {
      white-space: nowrap;
    }
    .ant-card-body {
      height: 100%;
    }
    .ant-card-head {
      padding: 0;
    }
    .ant-card-head-title {
      padding: 0;
    }
  }

  .titleWrapper {
    display: flex;
    padding: 12px 24px;
    align-items: flex-start;
    justify-content: space-between;
    @media (max-width: 576px) {
      padding: 12px 8px;
    }
  }

  .statusWrapper {
    display: flex;
    justify-content: space-between;
    align-items: baseline;
    flex-wrap: wrap;
  }

  .statusTagSetup,
  .statusTagLocked,
  .statusTagDeleted,
  .statusTagDemo,
  .statusTagArchived {
    display: flex;
    justify-content: flex-end;
    align-items: flex;
    div {
      width: fit-content;
      background-color: #fff;
      font-size: 16px;
      height: fit-content;
      padding: 4px 12px;
      display: flex;
      align-items: center;
      color: #fff;
      border-radius: 4px;
    }
  }

  .statusTagSetup {
    div {
      background-color: @sapphire;
      border: 1px solid @sapphire;
    }
  }

  .statusTagLocked {
    div {
      background-color: #343434;
      border: 1px solid #343434;
    }
  }

  .statusTagDeleted {
    div {
      background-color: #ac1717;
      border: 1px solid #ac1717;
    }
  }

  .statusTagArchived {
    div {
      background-color: rgba(36, 45, 65, 0.7);
      border: 1px solid rgba(36, 45, 65, 0.7);
    }
  }

  .statusTagDemo {
    div {
      background-color: @scheduledBlue;
      border: 1px solid @scheduledBlue;
    }
  }

  .actionsWrapper {
    display: flex;
    gap: 8px;
  }

  .favouriteButton {
    line-height: normal;
    background: #fff;
    border: 2px solid @brandBlue;
    border-radius: 5px;
    padding: 5px;
    cursor: pointer;
  }

  .unFavouriteButton {
    line-height: normal;
    background: @brandBlue;
    border: 2px solid @brandBlue;
    border-radius: 5px;
    padding: 5px;
    cursor: pointer;
  }

  .lftCol {
    position: relative;
    z-index: 9;
    @media (max-width: 576px) {
      margin-bottom: 10px;
    }
  }

  .estimateLabel {
    color: @gray;
    background: @blackExtraLight;
  }

  .aircraftImg {
    width: 75%;
    height: 75px;
    background-position: center left;
    background-size: contain;
    background-repeat: no-repeat;
  }

  .aircraftCardWrapper {
    line-height: 20px;
  }

  .aircraftRegText {
    font-weight: 600;
    color: @black;
    font-size: 14px;
  }
  .aircraftRegWrapper {
    display: flex;
  }

  .designatorText,
  .designatorTextNoMargin {
    font-weight: 350;
    font-size: 14px;
    margin-bottom: 2px;
    margin-left: 4px;
  }

  .designatorText {
    margin-left: 4px;
  }

  .flightTag {
    color: @gray;
    background: @blackExtraLight;
    font-size: 12px;
    font-weight: 600;
    margin-left: 10px;
    padding-left: 7px;
    padding-right: 7px;
  }

  .inFlightTag {
    margin-left: 10px;
  }

  .aircraftStatus {
    .tagUnserviceable {
      :global {
        .ant-tag-red {
          color: #ac1717;
        }
      }
    }
  }

  .statusLabel {
    font-size: 12px;
    color: #b9b9b9;
  }

  .statusTitleGreen {
    span {
      color: @green;
    }
  }

  .statusTitleAmber {
    span {
      color: @amber;
    }
  }

  .statusTitleRed {
    span {
      color: @red;
    }
  }

  .summaryItemWrapperDisabled {
    .statusTitle {
      span {
        color: rgba(0, 0, 0, 0.65);
      }
    }
  }

  .statusTag {
    font-weight: 600;
    border: 0;
    border-radius: 0;
  }

  .statusTitle {
    width: 185px;
    text-align: left;
    font-size: 14px;
    line-height: 1.86;
    color: rgba(49, 55, 69, 0.6);
    span {
      font-weight: 500;
    }
  }

  .nonFlexStatusTitle {
    width: 96px;
    display: inline-block;
  }

  .dueTag {
    font-weight: 600;
    border: 0;
    border-radius: 0;
  }

  .healthIndicator {
    display: inline-block;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    margin-right: 5px;
    background-color: @amber;
  }

  .healthIndicatorGreen {
    display: inline-block;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    margin-right: 5px;
    background-color: @green;
  }

  .defectLimitationNumber {
    display: inline-block;
  }

  @media (max-width: 576px) {
    .dataRow {
      margin-bottom: 5px;
    }
    .xxsCol {
      width: 100%;
      float: unset;
      overflow-x: wrap;
    }
  }
}

.summaryItemWrapper,
.summaryItemWrapperUpper {
  display: flex;
  align-items: center;
  text-align: left;
  :global {
    .ant-badge-status {
      width: 100%;
    }
  }
}

.summaryItemWrapperUpper {
  margin-top: 19px;
}

.summaryItemWrapperDisabled {
  opacity: 0.5;
}

.criticalCard {
  border: 1px solid @amber;
}

.overdueCard {
  border: 1px solid @red;
}

.setupCard {
  border: 1px solid @sapphire;
}

.lockedCard {
  border: 1px solid #343434;
}

.deletedCard {
  border: 1px solid #ac1717;
}

.archivedCard {
  border: 1px solid rgba(36, 45, 65, 0.7);
}

.demoCard {
  border: 1px solid @scheduledBlue;
}

.loaderOpacity {
  opacity: 0.7;
}

.mapImage {
  position: absolute;
  object-fit: cover;
  bottom: 0;
  -webkit-filter: grayscale(100%);
  filter: grayscale(100%);
  right: 0;
  height: 71%;
  width: 55%;
  -webkit-mask-image: radial-gradient(ellipse 169% 327% at 87% 104%, white 20%, transparent 50%);
  @media (max-width: 576px) {
    display: none;
  }
}

.okMapImage {
  bottom: -1px;
  right: -1px;
  height: 75%;
}

.locationWrapper {
  position: absolute;
  bottom: 26.5%;
  right: 25.5%;
  @media (max-width: 576px) {
    display: none;
  }
}

.locationName {
  position: inherit;
  bottom: 55.5%;
  margin-bottom: 25px;
}

.aircraftLocation {
  display: none;
}

@media screen and (max-width: 1200px) {
  .aircraftCard {
    .statusTitle {
      width: 235px;
    }
    .xxsColParent {
      padding-left: 9px;
    }
    :global {
      .ant-card-body {
        padding-left: 12px;
        padding-right: 12px;
      }
    }
  }
}

@media screen and (max-width: 991px) {
  .aircraftCard {
    .xxsColParent {
      padding-left: 0;
    }
  }
}

@media screen and (max-width: 576px) {
  .xxsColParent {
    height: auto;
    margin-top: 5px;
    width: 100%;
  }
  .summaryItemWrapper,
  .summaryItemWrapperUpper {
    line-height: 1.2;
  }
  .aircraftCard {
    height: auto;
    .aircraftImg {
      width: 50%;
    }
    .statusWrapper {
      display: block;
    }
  }
  .aircraftLocation {
    display: block;
    margin-top: 8px;
    .tagLocation {
      margin-left: 6px;
    }
  }
  .lftCol {
    :global {
      .ant-row {
        display: flex;
      }
    }
    .aircraftStatus {
      width: 50%;
      padding-left: 35px;
      :global {
        .ant-col {
          width: 100%;
          margin-bottom: 8px;
        }
      }
      .tagUnserviceable {
        :global {
          .ant-tag-red {
            text-wrap: wrap;
          }
        }
      }
    }
  }
}
