@import '../utils/utils.less';
@import '~antd/lib/style/themes/default.less';

.contentLayout {
  margin: 0 24px 0;
}

@media screen and (max-width: @screen-md) {
  .contentLayout {
    margin: 0;
  }
}
