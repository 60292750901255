@import '~antd/lib/style/themes/default.less';
@import '../../utils/utils.less';

.innerTextWrapper {
  width: 100%;
  height: 100%;
  .innerNumber,
  .redStyle,
  .criticalStyle,
  .redStyleNoMargin {
    font-size: 18px;
    font-weight: 500;
    letter-spacing: -0.12px;
    text-align: center;
    color: #393f4f;
    margin-bottom: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .innerName,
  .innerNameOverdue,
  .innerNameCritical {
    font-size: 14px;
    font-weight: 400;
    text-align: center;
    color: #9197a1;
  }
  .redStyle,
  .innerNameOverdue {
    color: #ff4040;
  }
  .criticalStyle,
  .innerNameCritical {
    color: #f2a650;
  }
  .redStyleNoMargin {
    color: #ff4040;
    margin-bottom: 0;
  }
  .greyText {
    color: #9197a1;
  }
}

.buttonLink {
  cursor: pointer;
  background-color: transparent;
  border: none;
  padding: 0;
  &:focus {
    border: none;
  }
}

.linkButton {
  cursor: pointer;
}

.warningCircle {
  width: 10px;
  height: 10px;
  background-image: linear-gradient(to bottom, #fad288, #f2a650);
  border-radius: 50%;
  margin-right: 5px;
  margin-left: -15px;
}

.includingTolerance {
  font-size: 14px;
  font-weight: 300;
  letter-spacing: -0.09px;
  color: #9197a1;
  padding-left: 5px;
  width: max-content;
}

.total {
  margin-top: 15px;
}
.total,
.nextDue {
  display: flex;
  justify-content: space-between;
  padding: 5px;
  max-width: 130px;
}
.label {
  font-size: 14px;
  font-weight: 300;
  letter-spacing: -0.09px;
  color: #9197a1;
}
.value,
.greyValue {
  font-size: 14px;
  font-weight: 500;
  letter-spacing: -0.09px;
  text-align: right;
  color: #393f4f;
  padding-left: 3px;
  display: flex;
  align-items: center;
}
.greyValue {
  color: #9197a1;
}

.progressBar {
  :global {
    .ant-progress-circle-trail {
      stroke-width: 1px;
    }
  }
}
