@import '../../utils/utils.less';

.menuButton,
.menuButtonTable {
  // padding: 0 0 0 0.5rem;
  color: @blue;
  position: relative;
  width: 100%;
  height: 32px;
  display: flex;
  align-items: center;
  .moreIcon {
    // padding-right: 8px;
    height: 1rem;
    color: @blue;
  }
}

.menuButtonTable {
  justify-content: flex-end;
}

.iconWrapper {
  div,
  img,
  button,
  input {
    box-sizing: revert;
    line-height: normal;
  }
  img {
    margin: 0 !important;
    padding: 0;
  }
  button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 24px;
  }
}

.menuItemSpan {
  width: 100%;
  text-align: left;
}

.menuWrapper {
  box-shadow: 0 0 10px 5px #dbe3ed;
  background-color: @white;
  border-radius: 4px;
  width: max-content;
  min-width: 80px;
  z-index: 310;
  overflow: visible;
  .menuItem {
    width: 100%;
    z-index: 20;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    min-width: 80px;
    height: 37px;
    cursor: pointer;
    background-color: @white;
    border: none;
    padding: 0;
    span {
      padding: 10px;
    }
    a {
      color: rgba(0, 0, 0, 0.65);
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: flex-start;
      align-items: center;
    }

    &:first-child {
      border-top-left-radius: 4px;
      border-top-right-radius: 4px;
    }

    &:last-child {
      border-bottom-left-radius: 4px;
      border-bottom-right-radius: 4px;
    }

    &:hover {
      background-color: rgba(18, 111, 214, 0.05);
      color: #126fd6;
      a {
        color: #126fd6;
      }
    }

    &.delete {
      color: @red;
      background-color: rgba(202, 35, 34, 0.05);
    }
    &.delete:hover {
      background-color: rgba(202, 35, 34, 0.1);
    }
  }
}

.invisibleBackground {
  width: 100vw;
  height: 100vh;
  left: 0;
  top: 0;
  position: absolute;
  background-color: transparent;
  padding: 0;
  border: none;
  z-index: 500;
}
