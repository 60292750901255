@import '~antd/lib/style/themes/default.less';

@footer-height: 202px;

.contentLayout {
  :global(.ant-layout) {
    min-height: ~'calc(100vh - @{footer-height})';
    background: #f6f8fb;
  }
  :global {
    .antd-pro-layouts-inner-menu-layout-innerMenuLayout + .ant-layout-footer {
      margin-left: 128px;
    }
    @media screen and (max-width: @screen-sm) {
      .antd-pro-layouts-inner-menu-layout-innerMenuLayout + .ant-layout-footer {
        margin-left: 0;
      }
    }
  }
}

:global(.ant-layout) {
  background: #f6f8fb;
  -webkit-box-sizing: inherit;
  box-sizing: inherit;
}

@media screen and (max-width: @screen-lg) {
  .contentLayout {
    height: 100%;
  }
}
